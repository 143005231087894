
import { Component, Vue } from 'vue-property-decorator'
import CurrentPermitsCard from '@/pages/permits/CurrentPermitsCard'
import OpenPermitsCard from '@/pages/permits/OpenPermitsCard'
import api from '@/utilities/api'
import { addId } from '@/utilities/functions'

@Component({
  components: {
    'c-current-permits-card': CurrentPermitsCard,
    'c-open-permits-card': OpenPermitsCard,
  },
})
export default class ManagePermitsScreen extends Vue {
  // data // ***
  awardedPermits: Record<string, any>[] = []
  openLocations: Record<string, any>[] = []

  // computed // ***
  get currentPermits(): Record<string, any>[] {
    return addId(this.awardedPermits)
  }
  get fetchAwardedPermitsLoading() {
    const { fetchAwardedPermits: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url })
  }
  get fetchOpenLocationsLoading() {
    const { fetchOpenLocations: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url })
  }
  get loading() {
    return this.fetchAwardedPermitsLoading || this.fetchOpenLocationsLoading
  }
  get openPermits(): Record<string, any>[] {
    return addId(this.openLocations)
  }
  get urls() {
    return {
      fetchAwardedPermits: 'permit/awardedPermits',
      fetchOpenLocations: 'permit/openLocations',
    }
  }

  // created // ***
  created() {
    this.onCreated()
  }
  async onCreated() {
    this.fetch()
  }

  // methods // ***
  async fetch() {
    await this.fetchAwardedPermits()
    await this.fetchOpenLocations()
  }
  async fetchAwardedPermits() {
    const { fetchAwardedPermits: url } = this.urls
    const res = (await api(url, undefined, undefined)) || {}
    if (res?.data) this.awardedPermits = res?.data
  }
  async fetchOpenLocations() {
    const { fetchOpenLocations: url } = this.urls
    const res = (await api(url, undefined, undefined)) || {}
    if (res?.data) this.openLocations = res?.data
  }
  onApply(e: Record<string, any>) {
    this.startApplication(e)
  }
  startApplication(e: Record<string, any>) {
    const url = `/permits/apply?location=${e?.permitAllocationLocationId}`
    this.$router.push(url)
  }
}
