
import { downloadAjax, downloadAjaxPdf } from '@/utilities/functions'
import { getFormattedDate } from '@/utilities/org'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CurrentPermitsCard extends Vue {
  // props // ***
  @Prop({ type: Array })
  permits!: Record<string, any>[]
  @Prop({ type: Boolean })
  loading!: boolean

  // data // ***
  downloadLoading: Record<string, boolean> = {}
  printLoading: Record<string, boolean> = {}

  // methods // ***
  getFormattedDate(date = '') {
    return getFormattedDate(date)
  }

  async download(permitAwardId: string) {
    Vue.set(this.downloadLoading, permitAwardId, true)
    await downloadAjax('permit/print/' + permitAwardId, 'PermitAward_' + permitAwardId + '.pdf')
    Vue.set(this.downloadLoading, permitAwardId, false)
  }
  getAmendUrl(permit: Record<string, any>): string {
    return `/permits/amend/${permit?.permitAwardId}`
  }
  onAmend(permit: Record<string, any>) {
    this.$router.push(this.getAmendUrl(permit))
  }
  async print(permitAwardId: string) {
    Vue.set(this.printLoading, permitAwardId, true)
    await downloadAjaxPdf('permit/print/' + permitAwardId, 'PermitAward_' + permitAwardId + '.pdf')
    Vue.set(this.printLoading, permitAwardId, false)
  }
}
