
import { getFormattedDate } from '@/utilities/org'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class OpenPermitsCard extends Vue {
  // props // ***
  @Prop({ type: Array })
  permits!: Record<string, any>[]
  @Prop({ type: Boolean })
  loading!: boolean

  // methods // ***
  formatDate(date: string) {
    return getFormattedDate(date)
  }
  onApply(e: Record<string, any>) {
    this.$emit('apply', e)
  }
}
